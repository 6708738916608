'use client'

import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

export default function HomeTestimonialSection() {
    return (
        <section className=" theme-pt-40 theme-pb-40 theme-pl-30 theme-pr-30">
            <div className="auto-container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 theme-mb-30">
                        <div className="site-heading text-left">
                            <h2>DEPOIMENTOS</h2>
                            <p>Opinião de alguns de nossos clientes</p>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mr-auto ml-auto">
                        <Swiper
                            id='testimonial'
                            modules={[Navigation]}
                            autoplay={true}
                            spaceBetween={30}
                            loop={true}
                        >
                            <div className='swiper-wrapper'>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Recomendo de olhos fechados, atendimento nota 1000, e ainda com uma educação maravilhosa na venda do carro Que Deus abençoe vcs e que tenham muitas vendas .Amei meu carro novo 🙏</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Rafael Gonçalves</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Loja exepcional, no pré venda e no pós venda. Nos deram toda atenção desde o primeiro contato. Entregam o carro todo revisado, como óleo, filtros, velas, correias trocadas, e com garantia. Recomendo com toda certeza !!!</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Junior Souza</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Já comprei dois carros com eles e só tenho elogios. Atendimento impecável, me orientaram durante a compra e pós compra também. Empresa idônea que eu recomendo para todos. São bons de negócios, e deixam o cliente satisfeito ao finalizar a compra. SUPER RECOMENDO!!!</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Fabiane Camilo</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </div>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}